import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Link } from "gatsby";
import EditGuide from "../../collections/EditGuide";

import { update_Guide, removeGuide } from "../../actions";
import { navigate } from "gatsby";

import triage_data from "../../data/triage.json";

const Edit = ({ location, navigate, search }) => {
  const dispatch = useDispatch();

  const guides = useSelector((state) => state.guides);
  const [title, update_title] = useState("as");
  const [text, update_text] = useState("");
  const [loading, update_loading] = useState(true);
  const [selected_guide, update_selected_guide] = useState(null);
  const [triage, update_triage] = useState(triage_data[0]);
  const [triage_prompt, update_triage_prompt] = useState(null);
  const [response, update_response] = useState(null);

  useEffect(() => {
    if (guides.length !== 0) {
      var find_guide = _.find(guides, { id: search.id });

      update_selected_guide(find_guide);
      update_text(find_guide.text);
      update_title(find_guide.title);
      try {
        find_guide.data = JSON.parse(find_guide.data);
        update_triage(find_guide.data);
      } catch (error) {}

      update_triage_prompt(find_guide.data2);
      update_response(find_guide.data3);
    }
  }, [guides]);

  function handleDelete() {
    console.log("delete" + search.id);
    dispatch(removeGuide(search.id));
    navigate("/guides/browse");
  }
  function updateTriage(data) {
    console.log("updating triage");
    update_triage(data);
  }

  function handleChangeText(text) {
    update_text(text);
  }

  function handleChangeTriagePrompt(text) {
    update_triage_prompt(text);
  }

  if (selected_guide === null) {
    return <div>Loading.....</div>;
  }

  function handleSave(values) {
    console.log("saving...");
    console.log(values);
    const data = {
      id: selected_guide.id,
      title: title,
      text: text,
      data: JSON.stringify(triage),
      data2: triage_prompt,
      data3: response,
    };

    console.log("data");
    console.log(data);
    dispatch(update_Guide(data));
    navigate("/guides/browse");
  }

  return (
    <>
      <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Edit Guides
        </h3>
        <div className="mt-3 flex sm:mt-0 sm:ml-4">
          <button
            onClick={() => handleDelete()}
            className="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Delete Guide
          </button>
        </div>
      </div>
      <EditGuide
        type={"edit"}
        title={title}
        text={text}
        update_title={update_title}
        handleChangeText={handleChangeText}
        handleSave={handleSave}
        updateTriage={updateTriage}
        handleChangeTriagePrompt={handleChangeTriagePrompt}
        triage={triage}
        triage_prompt={triage_prompt}
        response={response}
        update_response={update_response}
      />
    </>
  );
};

export default Edit;
